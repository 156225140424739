import {
  Container,
  Typography,
  TextField,
  Button,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Grow,
  Tabs,
  Tab,
  Card,
  Modal,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
} from "@mui/material";
import { LoadingButton, TabPanel } from "@mui/lab";
import * as React from "react";

import "./../../assets/scss/App.scss";
import GlobalService from "../../services/global";
import Details from "./Details";
import Transactions from "./Txs";
import VerifyLicense from "./VerifyLicense";
import { useSearchParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const BlockedMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '5px',
  fontWeight: 'bold',
}));  

function Users() {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');
  const [blockModal, setBlockModal] = React.useState(false);

  const [searchInput, setSearchInput] = React.useState(searchQuery || "");
  const [isSearchInpEmpty, setIsSearchInpEmpty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userInfo, setUserInfo]: any = React.useState({});
  const [isFetched, setIsFetched] = React.useState(false);
  const [value, setValue] = React.useState(sessionStorage.getItem("user-tab") && searchInput ? Number.parseInt(sessionStorage.getItem("user-tab") as string) : 0);

  const handleTabs = (tab) => {
    sessionStorage.setItem("user-tab", tab);
    setValue(tab);
  }

  const navigate = useNavigate();

  const fetchUser = async (query) => {
    if (!query) {
      return setIsSearchInpEmpty(true);
    } else {
      setIsLoading(true);
      setIsSearchInpEmpty(false);
      const resp = await GlobalService.httpGet(
        `api/admin/users/${query}`,
      );
      console.debug("fetchUsers", resp);

      if (resp) {
        setUserInfo(resp.userInfo);
        console.log("UserInfo on index.tsx:", resp.userInfo);
      }

      setIsFetched(true);
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!searchInput) {
      alert("Please enter a valid search query");
      return setIsSearchInpEmpty(true);
    }
    navigate(`/users?search=${searchInput}`);
  }

  React.useEffect(() => {
    if (searchQuery) {
      setSearchInput(searchQuery);
      fetchUser(searchQuery);
    }
  }, [window.location.href])


  const [stats, setStats] = React.useState({
	totalUsers: 0,
	activeUsers: 0,
	blockedUsers: 0,
	usersWithDep: 0
  })

  const getStats = async() => {
	const data = await GlobalService.httpGet("api/admin/users/stats");

	if (!data.isError) {
		setStats({
			totalUsers: data.totalUsers,
			activeUsers: data.totalUsers - data.blockedUsers,
			blockedUsers: data.blockedUsers,
			usersWithDep: data.usersDeposited
    })
    }
  }

  const [blocking, setBlocking] = React.useState(false);
  const blockUser = async () => {
    setBlocking(true);
    const data = await GlobalService.httpPost(`api/admin/users/${userInfo.blocked ? 'unblock' : 'block'}/${userInfo.id}`, {});
    if (!data.isError) {
      alert(`${userInfo.blocked ? 'Unblocked' : 'Blocked'} ${userInfo.name} (${userInfo.id}) successfully`);
      setBlockModal(false);
      setUserInfo({ ...userInfo, blocked: !userInfo.blocked });
    } else {
      alert("Error blocking user");
      setBlockModal(false);
    }
    setBlocking(false);
  }


  React.useEffect(()=> {
	getStats();
  },[])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  };
  return (
    <Container sx={{ width: "100%", paddingTop: "30px", paddingBottom: "30px" }}>
      <Modal
        open={blockModal}
        onClose={() => setBlockModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to {userInfo.blocked ? 'unblock' : 'block'} {userInfo.name}?
          </Typography>
          <Box sx={{justifyContent:'flex-end', display: 'flex', paddingTop: 1}}>
            <Button disabled={blocking} variant="contained" size="small" color="primary" >
              {'Cancel'}
            </Button>
            <LoadingButton loading={blocking} sx={{ marginLeft: '5px' }} size="small" variant="contained" color="error" onClick={blockUser}>
              {'Confirm'}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>

		<div style={{display: "flex", marginBottom: 30}} >
		<Card>
			<div style={{display: "flex",flexDirection: "row", padding: 20, alignItems: "stretch"}} >
				<div style={{display: "flex",flexDirection: "column", alignItems: "center", marginRight: 60}} >
					<Typography variant="h2" style={{ fontWeight: "400", lineHeight: "60px"}}>{stats.totalUsers}</Typography>
					<Typography variant="body2" style={{color: "#2196F3"}}>Total Users</Typography>
				</div>
				<div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}} >
					<Typography variant="body1">Active Users: <b>{stats.activeUsers}</b> ({((stats.activeUsers/stats.totalUsers)*100).toFixed(2)}%)</Typography>
					<Typography variant="body1">Blocked Users: <b>{stats.blockedUsers}</b> ({((stats.blockedUsers/stats.totalUsers)*100).toFixed(2)}%)</Typography>
					<Typography variant="body1">Users with deposit: <b>{stats.usersWithDep}</b> ({((stats.usersWithDep/stats.totalUsers)*100).toFixed(2)}%)</Typography>
				</div>
			</div>
		</Card>
		</div>
      <div style={{ width: "100%", alignItems: "center", display: "flex" }}>
        <TextField
          id="outlined-basic"
          label="Id/Email"
          variant="outlined"
          sx={{ width: "40%", marginRight: "10px" }}
          name="searchInput"
          type="search"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(e);
            }
          }}
          error={isSearchInpEmpty}
        />
        <LoadingButton
          variant="outlined"
          loading={isLoading}
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
        {
          isFetched && userInfo && userInfo.id &&
          <Button
            sx={{ margin: '5px' }}
            variant="contained"
            color="error"
            onClick={() => setBlockModal(true)}
          >
            {userInfo.blocked ? 'Unblock' : 'Block'} User
          </Button>
        }

      </div>
      <Tabs
        value={value}
        aria-label="basic tabs example"
        onChange={(e, val) => handleTabs(val)}
        style={{ marginTop: "10px" }}
      >
        <Tab label="Details" />
        <Tab label="Transactions" />
        <Tab label="Verify License" />
      </Tabs>
      
      {isLoading ? (
        <Grow in={isLoading}>
          <Skeleton sx={{ marginTop: "30px", height: "300px" }} />
        </Grow>
      ) : isFetched && userInfo.id ?(
        <>
          <Details userInfo={userInfo} value={value} />
          <Transactions userid={userInfo.id} value={value} />
          <VerifyLicense tabValue={value} userid={userInfo.id} licenseBack={userInfo.imageback} licenseFront={userInfo.imagefront} userName={userInfo.name} />
        </>
      ) : isFetched ? (
        <Typography sx={{ marginTop: "30px" }}>No Data</Typography>
      ) : null}
    </Container>
  );
}

export default Users;
