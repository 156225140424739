import {
  Container,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";

import "./../../assets/scss/App.scss";
import GlobalService from "../../services/global";
import Transaction from "./Transaction";
import FilterComponent from "./FilterComponent";

function AllTransactions() {
  const [_checked, set_checked] = React.useState({
    initiated: true,
    investing: true,
    pending: true,
    processing: true,
    rejected: false,
    successful: false,
  });
  // const _statuses = Object.keys(_checked);

  const [allTxs, setAllTxs] = React.useState<any[]>([]);
  const [filteredTxs, setFilteredTxs] = React.useState<any[]>([]);
  // const [filterTxs, setFilterTxs] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);

  // const getFilterTxUsingStatus = (data: []):Array<any> =>  {
  //   const hiddenStatuses = _statuses.filter((key) => !_checked[key]);

  //   return (data.filter((tx: any) => {
  //     if(!hiddenStatuses.includes(tx.status)) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }))
  // }

  function setTxs (txs: any[]) {
    setFilteredTxs(txs);
  }

  React.useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      // get tx types, product, tx status, dates, from session storage
      let reqStr = `api/admin/txs?`;

      reqStr += `include=accountID&`; 

      if (sessionStorage.getItem("all-tx-status") != null) {
        if (sessionStorage.getItem("all-tx-status") != "")
          reqStr += `status=${sessionStorage.getItem("all-tx-status")}&`
      } else {
        reqStr += `status=successful&`
      }

      if (sessionStorage.getItem("all-tx-types") != null) {
        if (sessionStorage.getItem("all-tx-types") != "")
          reqStr += `type=${sessionStorage.getItem("all-tx-types")}&`
      } else {
        reqStr += `type=Deposit&`
      }

      if (sessionStorage.getItem("all-tx-product-codes") != null) {
        if (sessionStorage.getItem("all-tx-product-codes") != "")
          reqStr += `product_codes=${sessionStorage.getItem("all-tx-product-codes")}&`
      }

      if (sessionStorage.getItem("all-tx-from") != null && sessionStorage.getItem("all-tx-to") != null) {
        if (sessionStorage.getItem("all-tx-from") != "" && sessionStorage.getItem("all-tx-to") != "")
          reqStr += `from=${new Date(sessionStorage.getItem("all-tx-from") as string).toISOString()}&to=${new Date(sessionStorage.getItem("all-tx-to") as string).toISOString()}`
      } else {
        reqStr += `from=${thirtyDaysAgo.toISOString()}&to=${today.toISOString()}`
      }

      const data = await GlobalService.httpGet(reqStr);
      // console.log("Data: ", data); // Add this line to inspect the response
      // console.log("trans: ", data);
      if (data) {
        setAllTxs(data); // changed from map - was trying something else out eariler
        setFilteredTxs(data);
      }
      setIsLoading(false);
    };
    console.log("here", {refresh});
    if(refresh) {
      fetchTransactions();
      setRefresh(false);
    }
  }, [refresh]);

  function getRows() {
    let rows: any[] = []
    filteredTxs.map((tx: any, index) => {
      rows.push(
        <Transaction 
          key={tx.id} 
          tx={tx} 
          index={index} 
          setRefresh={setRefresh} 
          tab="all" 
          // account_id={tx.account_id}
        />
      )
    })
    
    return rows
  }

  React.useEffect(()=> {
    console.log({refresh});
  }, [refresh])

  return (
    <Container sx={{ width: "100%", paddingTop: "30px" }}>
      <FilterComponent 
        // _checked={_checked}
        // filterTxs={filterTxs}
        // getFilterTxUsingStatus={getFilterTxUsingStatus}
        // setFilterTxs={setFilterTxs}
        // set_checked={set_checked}
        // txs={txs}
        setTxs={setTxs}
        tab={"all"}
        allTxs={allTxs}
        setIsLoading={setIsLoading}
        setAllTxs={setAllTxs}
      />

      <div style={{ marginTop: "30px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1200 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>UId</TableCell>
                <TableCell>Transaction Id</TableCell>
                <TableCell>Account ID</TableCell>
                <TableCell>Effective Date</TableCell>
                <TableCell>Product code</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Sub Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    {/* <Skeleton animation={false} /> */}
                  </TableCell>
                </TableRow>
              ) : !filteredTxs[0] ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Typography>No Data</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                getRows()
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}

export default AllTransactions;