import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RejectOptions = ({
  updatedKYCStatus,
  setUpdateKYCStatus,
  _user,
  defaultRemarks,
  ele,
}) => {
  const [showOther, setShowOther] = useState(updatedKYCStatus[ele.remarksField] || !defaultRemarks[ele.remarksField][0]);

  const status = [`INCOMPLETE`, `PROCESSING`, `APPROVED`]

  const handleStatusOrRemarkTextChange = (
    event:
      | SelectChangeEvent
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {

    let temp = updatedKYCStatus;
    temp[field] = event.target.value as string;

    setUpdateKYCStatus({ ...temp });
  };

  const handleRemarkChange = (event: SelectChangeEvent, field: string) => {
    let temp = updatedKYCStatus;
    if (event.target.value === "other") {
      temp[field] = "";

      setShowOther(true);
    } else {
      temp[field] = event.target.value as string;
      setShowOther(false);
    }
    setUpdateKYCStatus({ ...temp });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="status-options">Status</InputLabel>
          <Select
            labelId="status-options"
            id="demo-simple-select"
            value={updatedKYCStatus[ele.statusField]}
            label="Status"
            size="small"
            onChange={(event) =>
              handleStatusOrRemarkTextChange(event, ele.statusField)
            }
          >
            {status.map((st) => (
              <MenuItem value={st}>{st}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: "20px", width: "100%" }}>
          <InputLabel id="remark-options">Remark</InputLabel>

          <Select
            labelId="remark-options"
            id="remark-options"
            value={showOther ? "other" : updatedKYCStatus[ele.remarksField]}
            label="Remark"
            size="small"
            onChange={(event) => handleRemarkChange(event, ele.remarksField)}
          >
            {defaultRemarks[ele.remarksField].map((rm) => (
              <MenuItem value={rm}>{rm}</MenuItem>
            ))}
            <MenuItem value={"other"}>Other</MenuItem>
          </Select>
        </FormControl>
      </div>
      {showOther ? (
        <Box
          component="form"
          // sx={{
          //   "& .MuiTextField-root": { m: "10px 0", width: "50%" },
          // }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="other-remark"
            label="Other Remark"
            variant="outlined"
            size="small"
            sx={{ width: "100%", marginTop: "20px" }}
            value={updatedKYCStatus[ele.remarksField]}
            onChange={(event) =>
              handleStatusOrRemarkTextChange(event, ele.remarksField)
            }
          />
        </Box>
      ) : null}
    </>
  );
};

export default RejectOptions;
