import * as React from "react";
import { Container, Tabs, Tab } from "@mui/material";
import FailedKYCs from "./FailedKYCs";

const reactLogo = require("./../../assets/img/react_logo.svg");
import "./../../assets/scss/App.scss";
import ProcessingKYC from "./ProcessingKYC";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && props.children}
    </div>
  );
}

function KYC() {
  const [value, setValue] = React.useState(0);

  return (
    <Container sx={{ width: "100%" }}>
      <Tabs
        value={value}
        aria-label="basic tabs example"
        onChange={(e, val) => setValue(val)}
      >
        <Tab label="To Review" />
        <Tab label="Failed KYCs" />
      </Tabs>
      <TabPanel value={value} index={0} >
		<ProcessingKYC />
	  </TabPanel>
      <TabPanel value={value} index={1} >
		<FailedKYCs />
	  </TabPanel>
    </Container>
  );
}

export default KYC;
