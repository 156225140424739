import React, {useState, useEffect} from "react";
import GlobalService from "../../services/global";
import { Box, Button, Card, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RejectOptions from "../../components/RejectOptions";

type userProps = {
  id: string;
  name: string;
  email: string;
  imagefront: string;
  imageback: string;
  legal_name: string;
};

type accountProps = {
    owner_names: string;
    account: string;
    city: string;
    state: string;
    status: string;
    street1: string;
    street2: string;
    country: string;
};

const TitleAndValue = ({title, value}: {title: string, value:string}) => (
	<div style={{ display: "flex", flexDirection: "column", width: "45%", maxWidth: "45%" }}>
		<Typography variant="subtitle2" style={{ color: "grey" }}>
			{title}
		</Typography>
		<Typography variant="h6" sx={{ marginTop: "0px" }}>
			{value}
		</Typography>
	</div>
)

const User = (user: userProps) => {
	const [accounts, setAccounts] = useState<Array<accountProps> | null>()
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState<"APPROVING"|"REJECTING"|false>(false);
	const [showRejectOptions, setShowRejectOptions] = useState(false);
	const [updatedKYCStatus, setUpdateKYCStatus] = useState<any>({});
	const labelsMapping = [
		{ statusField: "overallStatus", remarksField: "overallRemarks" },
		// { statusField: "license", remarksField: "licenseRemarks" },
		{ statusField: "bankAccount", remarksField: "bankAccountRemarks" },
		// { statusField: "userInfo", remarksField: "userInfoRemarks" },
		// { statusField: "identityNumber", remarksField: "identityNumberRemarks" },
	];
	const defaultRemarks = {
		overallRemarks: ["Update license or link another bank account"],
		// licenseRemarks: ["Include all four corners and edges of the license", "Need high quality image with proper lighting without glare", "Only govt. issued license accepted"],
		bankAccountRemarks: ["Account holder's name doesn't match with the name on the license"],
		// userInfoRemarks: ["Mobile numbers only (no landline/VoIP numbers)", "Fullname must be as in license", "Date of birth should be as license"],
		// identityNumberRemarks: [],
	};
	useEffect(() => {
		labelsMapping.map((ele) => {
			let temp_current_status = {};
		
			let status = "PROCESSING";
			let remarks = defaultRemarks[ele.remarksField][0];
			
			temp_current_status[ele.statusField] = status
		
			temp_current_status[ele.remarksField] = status == 'INCOMPLETE' ? remarks : null
		
			setUpdateKYCStatus((currentState) => ({
				...currentState,
				...temp_current_status,
			}));
		});
	}, []);


	const getAccounts = async() => {
		setLoading(true);
		let resp = await GlobalService.httpGet(`api/admin/kyc/accounts/${user.id}`);
		console.log(resp);
		if (!resp.isError) setAccounts(resp.accounts);
		setLoading(false);
	}

	const approveKYC = async() => {
		setDisabled("APPROVING");
		let resp = await GlobalService.httpPost(`api/admin/kyc/approve/${user.id}`, {});
		console.log(resp);
		if(!resp.isError && resp.approved) {
			alert(`${user.name}'s KYC approved and notification sent`);
		}
		else if(resp.isError){
			alert(`ERROR: ${resp.error}`)
		}
		setDisabled(false);
	}

	const rejectKYC = async() => {
		if(!updatedKYCStatus.bankAccountRemarks || !updatedKYCStatus.overallRemarks) {
			return alert(`WARNING: Remarks not set`);
		}
		setDisabled("REJECTING");
		let resp = await GlobalService.httpPost(`api/admin/kyc/reject/${user.id}`, {updated_statuses: updatedKYCStatus});
		console.log(resp);
		if(!resp.isError && resp.rejected) {
			alert(`${user.name}'s KYC rejected and notification sent`);
		}
		else if(resp.isError){
			alert(`ERROR: ${resp.error}`)
		}
		setDisabled(false);
	}

	return (
		<Card sx={{ width: "100%", padding: "10px", marginBottom: "30px" }}>
		<Grid container spacing={2}>
			<Grid item xs={8}>
			<Typography variant="h5">#{user.id}</Typography>
			<div style={{display: "flex", width: "100%", flexWrap: "wrap", flex: 1, marginTop: "20px", gap: "20px"}} >
				<TitleAndValue title={"Name"} value={user.name} />
				<TitleAndValue title={"Legal Name"} value={user.legal_name} />
				<TitleAndValue title={"Email"} value={user.email} />
			</div>
			<div style={{display: "flex", width: "100%", flexWrap: "wrap", flex: 1, marginTop: "20px", gap: "20px"}} >
				{
					loading?
					<>
						<Skeleton width={"100%"} />
						<Skeleton width={"100%"} />
					</>
					:!accounts?
					<Button variant="contained" color="primary" onClick={getAccounts}>Get Accounts</Button>
					:accounts.length === 0?
					<Typography variant="body1">No Accounts</Typography>
					: 
					accounts.map((account, index) => (
						<>
							<TitleAndValue title={``} value={`Account #${index+1}`} />
							<TitleAndValue title={""} value={""} />
							<TitleAndValue title={"Account Holders Name"} value={account.owner_names} />
							<TitleAndValue title={"Account Number"} value={account.account} />
							<TitleAndValue title={"Street 1"} value={account.street1} />
							<TitleAndValue title={"Street 2"} value={account.street2} />
							<TitleAndValue title={"City"} value={account.city} />
							<TitleAndValue title={"State"} value={account.state} />
							<TitleAndValue title={"Country"} value={account.country} />
							<TitleAndValue title={"Acc Status"} value={account.status} />
						</>
					))
				}
			</div>
			<div style={{display: "flex", width: "100%", flexWrap: "wrap", flex: 1, marginTop: "20px", gap: "20px"}} >
				<LoadingButton variant="contained" color="success" disabled={disabled!==false||loading} loading={disabled === "APPROVING"||loading} onClick={approveKYC}>Approve KYC</LoadingButton>
				<Button variant="contained" color="error" onClick={()=>{setShowRejectOptions(!showRejectOptions)}}>Review Reject</Button>
			</div>
			{showRejectOptions && (
				<Box sx={{ padding: "10px", border: "1px solid black", marginTop: "20px" }}>
				{Object.keys(updatedKYCStatus).length > 0 &&
					labelsMapping.map((ele, index) => (
					<>
						<Typography sx={{ marginBottom: "5px" }}>
						{ele.statusField}
						</Typography>
						<RejectOptions
							defaultRemarks={defaultRemarks}
							setUpdateKYCStatus={setUpdateKYCStatus}
							updatedKYCStatus={updatedKYCStatus}
							_user={{}}
							ele={ele}
						/>
						{index !== labelsMapping.length - 1 && (
						<Divider
							sx={{ marginTop: "10px", marginBottom: "10px" }}
						/>
						)}
					</>
					))}

				<LoadingButton
					variant="contained"
					onClick={rejectKYC}
					sx={{ marginTop: "20px" }}
					color="error"
					disabled={disabled!==false||loading}
					loading={disabled==="REJECTING" || loading}
				>
					Confirm Reject
				</LoadingButton>
				</Box>
			)}
			</Grid>
			<Grid item xs={4}>
			<img src={user.imagefront} width="100%" />
			<img src={user.imageback} width="100%" />
			</Grid>
		</Grid>
		</Card>
	);
};

const ProcessingKYC = () => {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchProcessingKYCUsers = async () => {
    setLoading(true);
    let resp = await GlobalService.httpGet("api/admin/kyc/processing");
    if (!resp.isError) setUsers(resp.processingKYCUsers);
	setLoading(false);
  };

  React.useEffect(() => {
    if (!loading && users.length == 0) {
      fetchProcessingKYCUsers();
    }
  }, []);

  return (
    <div style={{width: "100%", marginTop: "30px"}}>
		{loading?
		<>
			<Skeleton />
			<Skeleton />
			<Skeleton />
		</>
		:users.map((user: any) => (
			<User {...user} />
		))}
    </div>
  );
};

export default ProcessingKYC;
