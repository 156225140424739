import * as React from 'react';
import { Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { DateTime } from 'luxon';

const Details = ({userInfo, value}: {userInfo: any, value: number}) => {
  // console.log(JSON.stringify(userInfo.accounts, null, 2));
	return (
		<Paper
          sx={{
            display: value===0?"flex":"none",
            flexDirection: "column",
            padding: "20px",
          }}
          elevation={2}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                User Id
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.id}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Email
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.email}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "20px", }}>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Name
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.name}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Legal Name
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.legal_name || "-"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Street 1
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.address?.street1 || "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Street 2
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.address?.street2 || "-"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Postal Code
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.address?.postalCode || "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                City
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.address?.city || "-"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                State
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.address?.state || "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Country
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.address?.country || "-"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Date Of Birth
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.dateOfBirth || "-"}
              </Typography>
            </div>
			<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Phone Number
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.phone_number || "-"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
			<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                KYC Overall Status
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.kyc_overall_status}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Created On
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {DateTime.fromISO(userInfo.createdon).setZone("America/New_York").toFormat("ccc, FF a")}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Wyre Account ID:
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.wyreInfo?.accountId || "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Wyre Account Status:
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.wyreInfo?.accountStatus || "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Wyre Payment ID:
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.wyreInfo?.paymentMethodId || "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                KYC Payment Status:
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "0px" }}>
                {userInfo.wyreInfo?.paymentMethodStatus || "-"}
              </Typography>
            </div>
          </div>
          {/* table for account details */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Acc Number</TableCell>
                    <TableCell>Account ID</TableCell>
                    <TableCell>Subtype</TableCell>
                    <TableCell>Acc Status</TableCell>
                    <TableCell>Plaid Status</TableCell>
                    <TableCell>Routing</TableCell>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>Bank Official Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    userInfo.accounts?.map((item:any)  => <TableRow key={item.account}>
                      <TableCell>{item.account}</TableCell>
                      <TableCell>{item.id || '-'}</TableCell>
                      <TableCell>{item.subtype}</TableCell>
                      <TableCell>{item.id == userInfo.primary_account_id ? 'Primary' : (item.deleted ? 'Deleted' : 'Active')}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>{item.routing}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.officialname}</TableCell>
                    </TableRow>)
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Effective APY</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Earnings</TableCell>
                    <TableCell>Accrued interest</TableCell>
                    <TableCell>Recurring Deposit</TableCell>
                    <TableCell>Pend Dep</TableCell>
                    <TableCell>Pend With</TableCell>
                    <TableCell>Proc With</TableCell>
                    <TableCell>Free credit balance</TableCell>
                    <TableCell>Free credit earnings</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    userInfo.productRelations.map((item:any)  => <TableRow key={item.productCode}>
                      <TableCell>{item.productCode}</TableCell>
                      <TableCell>{item.effective_apr}</TableCell>
                      <TableCell>{item.balance}</TableCell>
                      <TableCell>{item.earnings}</TableCell>
                      <TableCell>{item.accruedInterest}</TableCell>
                      <TableCell>{item.recurring_amount != '0' ? `Active (${item.recurring_amount})`: 'Not active'}</TableCell>
                      <TableCell>{item.pendingDeposits}</TableCell>
                      <TableCell>{item.pendingWithdrawals}</TableCell>
                      <TableCell>{item.processingWithdrawals}</TableCell>
                      <TableCell>{item.free_credit_balance}</TableCell>
                      <TableCell>{item.free_credit_earnings}</TableCell>
                    </TableRow>)
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Paper>
	);
}

export default Details;